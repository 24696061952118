<template>
  <b-modal id="edit-store-modal" hide-footer>
    <template #modal-title>
      <H1 v-if="store.store_uuid">Mağaza Bilgileri Düzenleme</H1>
      <H1 v-else>Yeni Mağaza Ekleme</H1>
    </template>

    <div class="d-block text-center">

      <b-col cols="12">
        <b-form-group label="Mağaza Kodu" label-cols-md="4">
          <b-input v-model="store.store_uuid" placeholder="Mağaza Kodu" disabled/>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group label="Şirket" label-cols-md="4">
          <v-select
            v-model="store.company_id"
            :options="_companies"
            label="name"
            :reduce="item => item.id"
            input-id="company"
            placeholder="Şirket"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group label="Pazaryeri" label-cols-md="4">
          <v-select
            v-model="store.marketplace_id"
            :options="_marketplaces"
            label="name"
            :reduce="item => item.id"
            input-id="marketplace"
            placeholder="Pazaryeri"
          />
        </b-form-group>
      </b-col>
      <hr>
      <b-col cols="12" v-if="storeOptions">
        <b-row v-for="option in storeOptions" :key="option">
          <b-col cols="12">
            <b-form-group :label="option" label-cols-md="4">
              <b-input v-model="store.name" :placeholder="option"/>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <hr>

      <b-col cols="12">
        <b-form-group label="Mağaza Adı" label-cols-md="4">
          <b-input v-model="store.name" placeholder="Mağaza Adı"/>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group label="Komisyon" label-cols-md="4">
          <b-input v-model="store.commission" placeholder="Ortalama Komisyon"/>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group label="Min Kar (%)" label-cols-md="4">
          <b-input v-model="store.profit_min" placeholder="Min Kar Ürün Başı"/>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group label="Maks. Kar (%)" label-cols-md="4">
          <b-input v-model="store.profit_max" placeholder="Maks. Kar Ürün Başı"/>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group label="Rekabet (₺)" label-cols-md="4">
          <b-input v-model="store.difference" placeholder="Ürün Başı Rakabet Farkı"/>
        </b-form-group>
      </b-col>

      Barem Bilgileri Girme Yapılacak
      <b-col cols="12">
        <b-form-group label="Karga Baremleri" label-cols-md="4">
          <b-input v-model="store.xx" placeholder="Baremler Gelecek" disabled/>
        </b-form-group>
      </b-col>

      <b-col offset-md="4">
        <b-button v-if="store.store_uuid" @click="updateStore" variant="primary" class="mr-1">
          Güncelle
        </b-button>
        <b-button v-else @click="savestore" variant="primary" class="mr-1">
          Kaydet
        </b-button>
      </b-col>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'

export default {
  name: 'EditStore',
  components: { vSelect },
  data: () => ({
    store: {},
  }),
  computed: {
    ...mapGetters('company', ['_companies']),
    ...mapGetters('marketplace', ['_marketplaces']),
    storeOptions() {
      if (!this.store.marketplace_id) return []
      return this._marketplaces[this.store.marketplace_id - 1].storeOptions
    },
  },
  methods: {
    updateStore() {
      alert()
    },
    savestore() {
      alert()
    },
  },
  mounted() {
    this.$store.dispatch('company/getCompanies')
    this.$store.dispatch('marketplace/getMarketplaces')
    this.$root.$on('bv::show::modal::data', store => {
      this.store = store
    })
  },
}
</script>

<style scoped>

</style>
