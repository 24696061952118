<template>
  <div>
    <b-card no-body>
      <b-card-body class="bv-example-row bv-example-row-flex-cols">
        <b-row>
          <b-col md="1">
            <img height="50" width="50" :src="'/img/py_logo/'+store.marketplace.image"/>
          </b-col>
          <b-col md="2">
            Mağaza : {{ store.name }}<br>
            Şirket : {{ store.company.name }}
          </b-col>
          <b-col md="8">
            <b-row>
              <b-col md="2">
                {{ store.commission }}
              </b-col>
              <b-col md="2">
                {{ store.profit_min }}
              </b-col>
              <b-col md="2">
                {{ store.profit_max }}
              </b-col>
              <b-col md="2">
                {{ store.difference }}
              </b-col>
              <b-col md="4">
                <CargoScale v-for="(scale,key) in store.scales" :key="key" :scale="scale"/>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="1">
            <b-button-group size="sm">
              <b-button variant="primary" @click="editStore">Düzelt</b-button>
            </b-button-group>
          </b-col>
        </b-row>
        <b-row>
          <!--<pre>{{ store }}</pre>-->
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import CargoScale from '@/views/store/CargoScale.vue'

export default {
  name: 'StoreName',
  components: { CargoScale },
  props: {
    store: {
      required: true,
      type: Object,
    },
  },
  methods: {
    editStore() {
      this.$root.$emit('bv::show::modal::data', this.store)
      this.$root.$emit('bv::show::modal', 'edit-store-modal')
    },
  },
}
</script>

<style scoped>

</style>
