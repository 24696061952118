<template>
  <div>
    <b-card no-body>
      <b-card-body class="bv-example-row bv-example-row-flex-cols">
        <H3>Mağazalar</H3>
      </b-card-body>
    </b-card>

    <b-card no-body>
      <b-card-body class="bv-example-row bv-example-row-flex-cols">
        <b-row>
          <b-col md="1">Pazaryeri</b-col>
          <b-col md="2">Mağaza</b-col>
          <b-col md="8">
            <b-row>
              <b-col md="2">Komisyon</b-col>
              <b-col md="2">Min Kar (%)</b-col>
              <b-col md="2">Max Kar (%)</b-col>
              <b-col md="2">Rekabet (₺)</b-col>
              <b-col md="4">Kargo Baremleri</b-col>
            </b-row>
          </b-col>
          <b-col md="1">
            <b-button size="sm" variant="primary" @click="getStores">Getir</b-button>
            <b-button variant="success" size="sm" @click="addStore">Ekle</b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <Store v-for="store in _stores" :key="store.id" :store="store"/>
    <EditStore/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Store from '@/views/store/Store.vue'
import EditStore from '@/views/store/EditStore.vue'

export default {
  name: 'StoreHome',
  components: {
    Store,
    EditStore,
  },
  computed: {
    ...mapGetters('store', ['_stores']),
  },
  methods: {
    getStores() {
      this.$store.dispatch('store/getStores')
    },
    addStore() {
      this.$root.$emit('bv::show::modal::data', {})
      this.$root.$emit('bv::show::modal', 'edit-store-modal', '#btnShow')
    },
  },
  mounted() {
    this.$store.dispatch('store/getStores')
  },
}
</script>

<style scoped>

</style>
